




















































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import {
  addDays, format, getUnixTime, isAfter, parse, startOfMonth,
} from 'date-fns';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import Meeting from '@/models/graphql/Meeting';
import { namespace, State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import EpAgendaDashboardItemComponent
  from '@/components/exhibitor-portal/dashboard/agenda/EpAgendaDashboardItemComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import PickerCalendarComponent from '@/components/calendar/PickerCalendarComponent.vue';
import PickerCalendarType from '@/utils/enums/PickerCalendarType';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import CommunityUser from '@/models/graphql/CommunityUser';

const companyCalendarStore = namespace('CompanyCalendarStore');

type FilterMenuOptionType = {
  key: string;
  list: Array<Meeting>;
  emptyMessage: string;
};
/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle */
@Component({
  components: {
    PickerCalendarComponent,
    FontAwesomeComponent,
    EpAgendaDashboardItemComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
  },
})
export default class EpAgendaDashboardWidget extends mixins(
  VueRegisterStoreWidget,
  VueBaseNotify,
  VueBaseWidget,
) {
  @companyCalendarStore.Action
  private loadMeetings!: (filter: object) => Promise<Meeting[]>;

  @companyCalendarStore.Action
  private loadCompanyReps!: (filter: object) => Promise<void>;

  @companyCalendarStore.State
  private meetings!: Meeting[];

  @companyCalendarStore.Getter
  private companyReps!: CompanyUserRole[];

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private isLoading = true;

  private selectedFilter = '0';

  private selectedCustomDateList: Meeting[] = [];

  private selectedCustomDateEmptyMessage = '';

  private showCalendar = false;

  private dateFilter = format(DateTimeHelper.getCurrentDateTime(), 'yyyy-MM-dd');

  private pickerCalendarType = PickerCalendarType;

  private now = DateTimeHelper.nowInZonedTime(this.selectedTzName);

  private filtersMenuOption: FilterMenuOptionType = {
    key: '0',
    list: [],
    emptyMessage: `${this.$tc('ep-dashboard.agenda.incoming-empty-message', 0, { date: 'today' })}`,
  };

  private get selectedMonth(): number {
    return getUnixTime(
      startOfMonth(
        parse(
          this.dateFilter,
          'yyyy-MM-dd',
          new Date(),
        ),
      ),
    );
  }

  private get baseGqlFilter(): object {
    const repsUids = this.companyReps.map((c) => (c.user as CommunityUser).uid);
    return {
      OR: [
        {
          creatorUser: { uid_in: repsUids },
        },
        {
          participants: { user: { uid_in: repsUids } },
        },
      ],
    };
  }

  private get list(): Meeting[] {
    if (this.selectedFilter === 'custom') {
      return this.selectedCustomDateList.filter((m) => isAfter(
        DateTimeHelper.utcToZonedTimeDate(`${m.startTime}Z`, this.selectedTzName),
        this.now,
      ));
    }
    return this.filtersMenuOption.list.filter((m) => isAfter(
      DateTimeHelper.utcToZonedTimeDate(`${m.startTime}Z`, this.selectedTzName),
      this.now,
    ));
  }

  private get emptyMessage(): string {
    if (this.selectedFilter === 'custom') {
      return this.selectedCustomDateEmptyMessage;
    }
    return this.filtersMenuOption.emptyMessage;
  }

  created(): void {
    this.setDataConfig();
    const { companyId } = this.$route.params;
    this.loadCompanyReps({
      company: {
        uid: companyId,
      },
      state: CompanyUserRoleStatusType.ACCEPTED,
    })
      .finally(() => {
        this.onFilter(this.selectedFilter);
      });
  }

  mounted(): void {
    setInterval(() => {
      this.now = DateTimeHelper.nowInZonedTime(this.selectedTzName);
    }, 1000);
  }

  // eslint-disable-next-line class-methods-use-this
  private nextDay(n: number): Date {
    return addDays(DateTimeHelper.getCurrentDateTime(), n);
  }

  private onSelectedCustomDateFilter(filter: string[]): void {
    if (filter.length > 0) {
      this.isLoading = true;
      // eslint-disable-next-line prefer-destructuring
      this.dateFilter = filter[0];
      this.selectedFilter = 'custom';
      this.loadMeetings({
        _inStartDate: {
          dates: [filter[0]],
          timezone: this.selectedTzName,
        },
        ...this.baseGqlFilter,
      })
        .then((response: Meeting[]) => {
          this.selectedCustomDateList = response;
          this.selectedCustomDateEmptyMessage = `${this.$tc('ep-dashboard.agenda.incoming-empty-message', 0, {
            date: format(
              parse(filter[0], 'yyyy-MM-dd', new Date()),
              `${this.$t('app.date.monthDayShort')}`,
              { locale: this.dateLocale },
            ),
          })}`;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  private onFilter(filter: string): void {
    this.isLoading = true;
    this.selectedFilter = filter;
    const date = this.nextDay(parseInt(this.selectedFilter, 10));
    this.dateFilter = format(
      date,
      'yyyy-MM-dd',
      { locale: this.dateLocale },
    );
    this.loadMeetings({
      _laterThenStartDate: {
        date: this.dateFilter,
        timezone: this.selectedTzName,
      },
      ...this.baseGqlFilter,
    })
      .then((response) => {
        this.filtersMenuOption.list = response;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private toggleCalendar(): void {
    this.showCalendar = !this.showCalendar;
  }

  private goToAgenda(): void {
    this.$router.push({
      name: 'exhibitor-portal-agenda',
    });
  }
}
